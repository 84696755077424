@font-face{
  font-family: 'roboto';
  src: url("./fonts/Roboto-Regular.ttf");
  font-style: normal;
}
@font-face{
  font-family: 'robotoHeader';
  src: url("./fonts/Roboto-Thin.ttf");
  font-style: lighter;
}

* {
  margin: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  font-family: roboto !important;
  -webkit-font-smoothing: antialiased !important
  
}

h4{
  font-size: 1.4rem;
}

h1{
  font-weight: normal;
}

p{
  margin-bottom: 0.5rem;
}
/* globals */

.btn-primary {
  display: inline-block;
  text-decoration: none;
  letter-spacing: var(--mainSpacing);
  color: black;
  background: #2ec3fd;
  padding: 0.4rem 0.9rem;
  border: 3px solid #2ec3fd;;
  text-transform: uppercase;
  cursor: pointer;
  font-family: "roboto3"
}
.btn-primary:hover {
  background: #f9c500;
  border: 3px solid #f9c500;
  text-decoration: none;
}
.loading {
  text-transform: capitalize;
  text-align: center;
  margin-top: 3rem;
}
.error {
  text-align: center;
  text-transform: uppercase;
  margin: 2rem 0;
}
.empty-search {
  text-align: center;
  text-transform: capitalize;
  margin: 2rem 0;
  padding: 1rem;
  letter-spacing: var(--mainSpacing);
}
/* end of globals */
/* Navbar */
.header{
  top: 0;
  width: 100%;
  height: 120px;
}

.header-top{
  height: 50px;
  background-color: #2ec3fd;
  display: flex;
}

.header-bottom{
  height: 70px;
  display: flex;
  background-color: #fff;
}

.header-top-right{
  float: right;
}

.header-top-left{
  float: left;
}

.header-top-left span{
  padding-top: 0.6rem;
  display: inline-block;
  margin-right: 9px;
  font-size: 14px;
  color:white;
  font-family: 'roboto';
}


.header-top-right span{
  padding-top: 0.3rem;
  display: inline-block;
  margin-right: 6px;
  font-size: 19px;
  color:#FFF;
  font-family: roboto;
}

.header-top-right span a{
  text-decoration: none;
  color: #fff;
}

.header-top-right span button{
  background-color: Transparent;
  border: none;
  color: #fff;
  outline: none;
  width: 10px;
  padding: 5px;
  padding-left: 0px;
}

.header-top-right span a:hover,.header-top-right span button:hover{
  color: #337ab7;
}

.container-header{
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  width: 1170px;
}

.header-top-left h6{
  font-size:14px;
  font-family: roboto;
  font-weight: bold !important;
}

.header-top-left a{
  text-decoration: none;
  color:#fff
}

.header-top-left a:hover{
  color:#FFF;
  text-decoration: underline;
}
.nav-left{
  float: left;
}

.nav-right{
  float: right;
}

.header nav img{
  width: 135px;
  height: 50px;
  margin-top: .75rem
}

.header nav ul{
  float: right;
}
.header nav ul li{
  position: relative;
  float: left;
  line-height: 70px;
}

.header nav ul li a{
  position: relative;
  color: #353535;
  text-decoration: none;
  font-family: roboto;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 14px;
  padding: 10px 15px;
}
.header nav ul li:hover{
  background-color: #2dc3fc;
  color:#FFF;
}

.active{
  background-color: #2dc3fc;
}

.active a{
  color:#FFF !important;
}

.navbarbtn{
  display: inline-block;

  font-weight: normal;

  text-align: center;

  cursor: pointer;

  background-image: none;

  border: 1px solid transparent;

  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;

  padding: 6px 12px;

  font-size: 14px;

  font-family: roboto2;

  line-height: 1.42857143;

  border-color: #f9c500;

  background-color: #f9c500;

  color: #fff;

  margin-top: 1.1rem;

  margin-left: 9px;
}

.navbarbtn:hover{
  background-color: #2ec3fd;
  border-color:#2ec3fd;
}

.header-top-left2{
  float: left;
  margin-top: 0.3rem;
  display: none;
}

.header-top-left2 span{
  padding-top: 0.6rem;
  display: inline-block;
  margin-right: 9px;
  font-size: 14px;
  color:white;
  font-family: 'roboto';
}
.header-top-left2 h6{
  font-size:14px;
  font-family: roboto;
  font-weight: bold !important;
}

.header-top-left2 a{
  text-decoration: none;
  color:#fff
}

.header-top-left2 a:hover{
  color:#FFF;
  text-decoration: underline;
}



@media (max-width:767px){

  .header-top-right{
    display: none;
  }

  .header-top-left{
    margin-left: 1rem;
  }
  .header nav img{
    margin-left:.5rem;
  }

  .nav-right{
    display:none;
  }
  

  .dropdown{
    display: block
  }

}

@media (max-width: 980px){
  .navbarbtn{
    display: none;
  }
}

@media (max-width:1200px){
  .header-top-left{
    margin-left: 1rem;
  }
  .nav-left{
    margin-left: 1rem;
  }

}



@media (max-width:400px){
  .header nav img{
    width: 125px;
    height: 50px;
  }
  
}

@media (max-width:360px){
  .header nav img{
    margin-left: 0.2rem;
    width: 115px;
    height: 45px;
  }
  
}



/* end of navbar */
/* Hero */
.defaultHero,
.apartamentosHero, .alojamentoHero{
  height: 700px;
  display: flex;
  background: url("./images/img1.jpg") center/cover no-repeat;
  align-items: center;
}

.apartamentoHero {
  background-image: url("./images/img4.jpg");
  background-repeat: no-repeat;
  height: 600px;
}

.alojamentoHero{
  background-image: url("./images/Alojamento.jpg");
  height: 600px;
}
/* End of Hero */
/* SlideShow */

/* End Of SlideShow */

/* Title */
.section-title {
  text-align: center;
  margin-bottom: 2rem;
}
.section-title h4 {
  font-size: 40px;
  text-transform: capitalize;
  margin-bottom: 1rem;
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 10px;
  color: #504f46;
  font-weight: lighter;
}

/* end of Title */
.section-title-quartos {
  text-align: center;
  margin-bottom: 4rem;
}

.section-title-quartos-tit{
  margin-top:0;
}

.section-title-quartos-tex{
  margin-top: 0;
}

.section-title-quartos h4 {
  font-size: 40px;
  font-family: "roboto1";
  text-transform: capitalize;
  color: #504f46;
  font-weight: lighter;
  padding-bottom: 5px;
  color: #504f46;
}
.section-title-quartos h6 {
  font-size: 1.5rem;
  font-family: "roboto1";
  text-transform: capitalize;
  margin-bottom: 1rem;
  color: #2ec3fd;
  font-weight: lighter;
  padding-bottom: 10px;
}
/* services */
.services {
  padding: 2rem 0;
}
.services {
  background: #fff;
  text-align: center;
}
.services-center {
  width: 90vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-row-gap: 1rem;
  grid-column-gap: 50px;
}
.service span {
  display: inline-block;
  width: 80px;
  height: 80px;
  color: var(--primaryColor);
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  border: 1px solid #8f8f8f;
  border-radius: 50%;
}
.service span img{
  font-size: 50px;
  color: #8f8f8f;
}
.services h6 {
  font-size: 22px;
  font-weight:normal;
}
.services p {
  width: 80%;
  font-size: 14px;
}
.leftS{
  float: left;
  width: 10%;
  text-align: left;
}
.leftS i img{
  width: 20px;
  height: 21px;
}
.rightS{
  float: right;
  width: 90%;
  text-align: left;
  padding-left: 1rem;
  color: #504f46;
}

.rightS h5{
  font-size: 16px;
}
.rightS p{
  margin-bottom: 0px;
  font-size: 16px;
}


@media screen and (min-width: 992px) {
  .services-center {
    width: 95vw;
    max-width: 1170px;
  }
}

@media screen and (min-width: 1200px) {
  .services p {
    width: 100%;
  }
}
/*end of services */
/* featured rooms */

.featured-rooms {
  padding-top: 1.5rem;
  padding-bottom: 2.5rem;
  width: 1170px;
  margin: auto;
  height: 540px;
}
@media (max-width:1170px){
  .featured-rooms{
  width: 100%;
  height: auto;
  margin: 0 auto;
}
}
.featured-rooms-center {
  width: 80vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
  grid-row-gap: 2rem;
  grid-column-gap: 40px;
}
@media screen and (min-width: 776px) {
  .featured-rooms-center {
    width: 90vw;
    grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
  }
}
@media screen and (min-width: 992px) {
  .featured-rooms-center {
    width: 95vw;
    max-width: 1170px;
  }
}
/* end pf featured rooms */
/* room */
.room {
  cursor: pointer;
  border:1px outset transparent;
}

.roomv2 {
  cursor: pointer;
  margin-left: .5rem;
  margin-right: .5rem;
  border:1px outset transparent;
}
.oferta {
  cursor:pointer;
  border:1px outset transparent;
}

.ofertav2 {
  margin-left: 1rem;
  margin-right: 1rem;
  cursor:pointer;
  border:1px outset transparent;
}

.room:hover {
  border-color: #2dc3fc;
}
.roomv2:hover {
  border: 1px solid #2dc3fc;
}
.ofertav2:hover {
  border: 1px solid #2dc3fc
}
.oferta:hover{
    border: 1px solid #2dc3fc;
}

.img-container {
  position: relative;
}
.img-container img {
  width: 100%;
  display: block;
}

.room-link {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: scale(0);
}

.room-info {
  display: table;
  position: relative;
  border: 1px solid #f2f2f2;
  border-top: 0px;
}

.room-left{
  display: table-cell;
  padding-left: 15px;
  padding-right: 15px;
  height: 100%;
  vertical-align: middle;
  width: 99%;
}
.room-left-wrap{
  display:inline-block;
}

.room-left-wrap i{
  float:left;
  margin-right: 0.5rem;
  font-size: 13px;
  color:#5e5e5e;
}
.room-left-wrap h3{
  float:right;
  padding-top: .3rem;
}
.room-left h3{
  font-size: 14px;
  margin: auto;
  font-family: "roboto";
  color:#5e5e5e;
}
.room-left h2{
  margin-top: 10px;
  font-size: 22px;
  margin-bottom: 0px;
  color:#5e5e5e;
}

.room-right{
  display: table-cell;
  height: 100%;
  vertical-align: middle;
  text-align: center;
  font-style: italic;
  border-left: 1px solid #f2f2f2;
  background-color: #f7f7f7;
  padding: 4px 15px;
}

.room-price{
  color: #2ec3fd;
  font-size: 24px;
  font-family: "roboto";
  font-weight: bold;
  margin-top: 0.2rem;
  margin-bottom: 3px;

}
.room-right p{
  margin-top: -5px;
  margin-bottom: 5px;
  font-size: 12px;
  color: #898989;
}

@media only screen and (max-width: 470px){
  .room-left h3{
    font-size: 12px;

  }
  .room-left-wrap h3{
    font-size: 12px;
  }
  .room-left-wrap i{
    font-size: 13px;
  }
  
}

/* end of room  */
/* slider*/
.carousel-caption {
  top: 35%;
bottom: 60%;
left: 17%;
right: auto;
text-align: left;
}
.carousel-caption h5{
  font-size: 24px;
  margin: 0;
  padding: 15px 30px;
  color: #fff;
  background-color: #353535;
  float: left;
}
.carousel-caption p{
  font-size: 20px;
  margin: 0;
  padding: 15px 50px 15px 30px;
  color: #353535;
  background-color: #fff;
  text-shadow: none;
  float: left;
  clear: both;
  white-space: pre-line;
  min-width: 300px;
  max-width: 550px;
}

.carousel-inner {
  height: 100%;
}
.item {
  position: relative;
  display: none;
  -webkit-transition: 1s ease-in-out left;
  -moz-transition: 1s ease-in-out left;
  -o-transition: 1s ease-in-out left;
  transition: 1s ease-in-out left;
}
.item img{
  width: 100%;
  object-fit: cover;
  height: 100%;
}
.container-fluid{
  padding-right: 0px;
  padding-left: 0px;
}

.carousel-indicators {
  position: absolute;
  bottom: 10px;
  left: 50%;
  z-index: 15;
  width: 60%;
  margin-left: -30%;
  padding-left: 0;
  list-style: none;
  text-align: center;
}
.carousel-indicators li {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 1px;
  text-indent: -999px;
  border: 2px solid #FFF;
  border-radius: 10px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
}
.carousel-indicators .active {
  margin: 0;
  width: 12px;
  height: 12px;
  background-color: #2dc3fc;
  border-color: #2dc3fc;
}

.carousel-indicators li, .carousel-indicators li.active {
  margin: 0px 4px !important;
}

.carousel-item{
  height: 100%;
}

/* end of slider*/
/* single room*/

.single-room-div{
  width: 100%;
}

.single-room-container{
  width: 1170px;
  margin: 0 auto;
  padding-bottom: 0.5rem;
}

.single-room-infotl{
  float: right;
  padding-top: 0.4rem;
  display: flex
}

.single-room-infotl span{
  color:#504f46;
  font-family: roboto;
  display: flex;
  padding-right: 5px;
}

.single-room-infotl i{
  font-size: 13px;
}
.single-room-infotl h3{
    font-size: 15px;
    padding-top: 0.1rem;
    padding-left: 0.3rem;
}
.single-room-description{
  clear:both;
  padding-top: 2rem;
  padding: 1rem;
}
.single-room-description p{
  white-space: pre-line;
}
.single-room-images {
  width: 80vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-row-gap: 2rem;
  grid-column-gap: 50px;
}
.single-room-images img {
  width: 100%;
  display: block;
}

.leftSA{
  width: 50px;
  height: 50px;
  padding: 0.3rem;
  margin-top: .5rem;
}

.leftSA img{
    font-size: 50px;
    color: #8f8f8f;
}

@media only screen and (max-width: 1170px){
  .single-room-container{
    width: 100%;
  }
}

.room-extras {
  width: 80vw;
  margin: 0 auto 3rem auto;
}
.room-extras h6 {
  text-transform: capitalize;
  letter-spacing: var(--mainSpacing);
}
.extras {
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
  grid-column-gap: 2rem;
  grid-row-gap: 1rem;
}
@media screen and (min-width: 992px) {
  .single-room-images,
  .single-room-info,
  .room-extras {
    width: 95vw;
    max-width: 1170px;
  }
  .single-room-info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2rem;
  }
  .info {
    padding-left: 3rem;
  }
}
/* end of single room*/
/* roomlist */
.roomslist {
  padding: 2rem 0;
}
.roomslist-center {
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  grid-row-gap: 2rem;
  grid-column-gap: 2.5rem;
  padding-bottom: 3rem;
}

@media screen and (min-width: 400px) {
  .roomslist-center {
    width: 80vw;
  }
}
@media screen and (min-width: 992px) {
  .roomslist-center {
    width: 95vw;
    max-width: 1170px;
  }
}
/* end of roomlist */
/*  rooms fitler*/
.filter-container {
  padding: 5rem 0;
}
.filter-form {
  width: 60vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(202px, 1fr));
  grid-row-gap: 2rem;
  grid-column-gap: 40px;
}
.form-group {
  text-transform: capitalize;
}
.form-group label {
  display: block;
  letter-spacing: var(--mainSpacing);
  margin-bottom: 0.5rem;
}
.form-control {
  width: 100%;
  background: transparent;
  font-size: 1rem;
}
.size-inputs {
  display: flex;
}
.size-input {
  width: 40%;
  padding: 0.2rem;
  border: 1px solid var(--mainBlack);
  border-radius: 0.3rem;
  margin-right: 0.3rem;
}
.single-extra label {
  display: inline-block;
  font-size: 0.8rem;
  margin-left: 0.5rem;
}
@media screen and (min-width: 776px) {
  .filter-form {
    width: 70vw;
  }
}
@media screen and (min-width: 992px) {
  .filter-form {
    width: 95vw;
    max-width: 1170px;
  }
}
/* end of rooms fitler*/

/* APRESENTAÇÃO */


  .apblock{
    height: 820px;
    background-color:#f7f7f7
  }
  .wrapper-ap{
    width:100%;
  }
  .img-infoap{
    width:100%;
  }

  .img-infoap h3{
    padding: 30px 15px 20px;
    margin-bottom:5px;
    border-bottom: 1px solid #eeeeee;
    font-family: "roboto1";
    font-size: 30px;
    color: #504f46;
    line-height:44px;
  }
  .img-infoap h5{
    padding: 5px 15px 20px;
    font-family: "roboto1";
    font-size: 18px;
    color: #2dc3fc;
    line-height:44px;
  }
  .img-infoap p{
    padding: 0px 15px 20px;
    font-family: "roboto1";
    color: black;
    font-size: 16px;
    line-height:24px;
  }
  .img-ap{
    width:100%
  }

  @media only screen and (min-width:768px){

    .apblock{
      height: 850px;
    }
    
    .wrapper-ap{
      width:600px;
      margin:0 auto
    }

    .img-infoap h3{
      padding: 20px 160px 0px;
    }
  
    .img-infoap h5{
      padding: 0 120px 0.2rem;
      font-size: 20px;
    }
  
    .img-infoap p{
      padding: 20px 0px 0px;
    }

    .img-ap{
      padding-top:30px;
    }
    

  }
  
  @media only screen and (min-width:1000px){

    .apblock{
      height: 460px;
    }
    .wrapper-ap{
      width:1170px;
    }

    .img-infoap{
      width:50%;
      float:right
    }

    .img-infoap h3{
      padding: 65px 90px 15px;
      font-size:50px;
      font-weight:bold
    }
  
    .img-infoap h5{
      padding: 0 110px;
      font-size: 22px;
    }

    .img-infoap p{
      padding: 10px 0px 0px 30px;
      text-align: center;
    }
    .img-ap{
      padding-right: 7px;
      width:50%;
      float:left

    }

    

  }

  /* COMEÇO */
  .apblockN{
    height: 880px;
  }
  .wrapper-apN{
    width:100%;
  }
  .img-infoapN{
    width:100%;
  }

  .img-infoapN h3{
    padding: 30px 15px 20px;
    margin-bottom:5px;
    border-bottom: 1px solid #eeeeee;
    font-family: "roboto1";
    font-size: 35px;
    color: #504f46;
    line-height:44px;
  }
  .img-infoapN h5{
    padding: 5px 15px 20px;
    font-family: "roboto1";
    font-size: 18px;
    color: #2dc3fc;
    line-height:44px;
  }
  .img-infoapN p{
    padding: 0px 15px 20px;
    font-family: "roboto1";
    color: black;
    font-size: 16px;
    line-height:24px;
  }
  .img-apN{
    width:100%
  }

  @media only screen and (min-width:768px){

    .apblockN{
      height: 905px;
    }
    
    .wrapper-apN{
      width:600px;
      margin:0 auto;
    }

    .img-infoapN h3{
      padding: 40px 165px 0px;
    }
  
    .img-infoapN h5{
      padding: 0 120px 0.2rem;
      font-size: 20px;
    }
  
    .img-infoapN p{
      padding: 20px 0px 0px;
    }

    .img-apN{
      padding-top:30px;
    }
    

  }
  
  @media only screen and (min-width:1000px){

    .apblockN{
      height: 400px;
    }
    .wrapper-apN{
      width: 1170px;
    }

    .img-infoapN{
      width:50%;
      float:left
    }

    .img-infoapN h3{
      padding: 20px 0px 0px;
    }
  
    .img-infoapN h5{
      padding: 0 0px 0;
      font-size: 20px;
    }

    .img-infoapN p{
      padding: 10px 0px 0px;
    }
    .img-apN{
      width:50%;
      float:right;
      padding-top: 0px;
      padding-left: 50px;
    }

    

  }

    /* FIM */

    .HeroIMGN{
      text-align:center;
    }

    .HeroIMGN img{
      max-width:100%
    }


  /* SLIDER INICIO */
.blockS{
  background-color:#f7f7f7;
  padding-top: 30px;
  padding-bottom: 40px;
  height:380px;
}
.wrapperS{
    width:100%;

}

.PageS{
    width:100%;
    text-align:center;
}

.PageS i{
  color:#2dc3fc;
  font-size: 65px;
}

.blockS div{
  margin-bottom:0;
}

.PageS h5{
  font-family: 'roboto3';
  color: #353535
}

/* Block Partilha*/

.container-div div{
  height: 85px;
}


.container-div{
  background:#2ec3fd;
  align-items: center;
  width: 100%;
  
}

.centerblock{
  display: flex;
  width: 1170px;
  margin:0 auto;
}

.leftd{
  width: 15%;
}
.rightd{
  width: 15%;
}
.centerd{
  width:70%;
  text-align: center;
  color:#fff;
}

.centerd h2{
  font-family: 'roboto';
  padding-top: 1.3rem;
  font-size: 34px !important;
}

.partilhar{
  width: 100%;
  height: 100%;
  background: #f9c500;
  outline: none;
  border: none;
  font-size: 28px;
  font-family: 'roboto';
  font-style: italic;
  font-weight: lighter;
  color:white;
}
.partilhar:hover{
  background: #337ab7;
}

.verfotos{
  width: 100%;
  height: 100%;
  background: #2ec3fd;
  color:white;
  outline: none;
  border: none;
  font-size: 18px;
  font-family: 'roboto'
  
}

.verfotos:hover{
  color:#337ab7;
}

.verfotos svg{
  font-size: 15px;
  margin-right: 5px;
  margin-bottom: 3px;
}

@media only screen and (max-width:1170px){
  .centerblock{
    width: 100%;
  }
}
/**/

  /* COMEÇO */
  .apblockA{
    height: 840px;
    background-color:#FFF;
  }
  .wrapper-apA{
    width:100%;
  }
  .img-infoapA{
    width:100%;
  }

  .img-infoapA h3{
    padding: 30px 15px 20px;
    border-bottom: 1px solid #eeeeee;
    font-family: "roboto";
    font-weight: lighter;
    font-size: 40px;
    color: #504f46;
  }
  .img-infoapA h5{
    padding: 5px 15px 20px;
    font-family: "roboto";
    font-size: 22px;
    color: #2dc3fc;
    font-weight: lighter;
  }
  .img-infoapA p{
    padding: 0px 15px 20px;
    font-family: "roboto";
    color: black;
    font-size: 16px;
    line-height:24px;
  }
  .img-apA{
    width:100%
  }

  @media only screen and (min-width:768px){

    .apblockA{
      height: 860px;
    }
    
    .wrapper-apA{
      width:600px;
      margin:0 auto
    }

    .img-infoapA h3{
      padding: 40px 145px 0px;
    }
  
    .img-infoapA h5{
      padding: 0 190px 0.2rem;
      font-size: 20px;
    }
  
    .img-infoapA p{
      padding: 10px 0px 0px;
    }

    

  }
  
  @media only screen and (min-width:1000px){

    .apblockA{
      height: 385px;
    }
    .wrapper-apA{
      width:1170px;
    }

    .img-infoapA{
      width:50%;
      float:left
    }

    .img-infoapA h3{
      padding: 20px 0px 10px;
    }
  
    .img-infoapA h5{
      padding:0;
      font-size: 20px;
    }

    .img-infoapA p{
      padding: 10px 0px 0px;
    }
    .img-apA{
      padding-left: 30px;
      width:50%;
      float:right

    }

    

  }

.alojamentopf{
  background-color: #f8c500;
  text-align: center;
  width: 100%;
}

.alojementopfa{
  padding:35px;
  color:#fff;
}

#alojamentoa{
  text-decoration: none;
  color: #fff;
  font-size: 22px;
  font-weight: bold;
}

#alojamentoa:hover{
  color:black
}


/* FOOTER */

.footer{
  color:#FFF;
}

.footer .col{
  padding: 10px 15px !important;
}
.footer-top{
  background-color: #2ec3fd;
  padding-top: 25px;
  padding-bottom: 15px;
}

.footer-bottom{
  background-color: #008cc1;
  padding-top: 20px;
  padding-bottom: 20px;
  height: 90px;
}

.footerlogo{
  float:left;
  margin-right: 10px;
}

.footerlogo img{
  filter: invert(1%) sepia(1%) saturate(1%) hue-rotate(1deg) brightness(1000%) contrast(100%);
  height: 82px;
}

.footer-top p{
  font-size: 14px;
  margin-bottom: 1.8px !important;
  color: #FFF;
}

.footer-top h5{
  font-family: 'roboto';
  font-size: 14px;
  font-weight: bold;
  margin-bottom:10px;
  text-transform: uppercase;
  color: #FFF;
}

.footer-top ul{
  float: left;
}

.footer-top ul li{
  width: 50%;
  float:left;
  margin-bottom: 0.5rem;
}


.footer-top ul li a{
  text-decoration: none;
  color:#FFF;
  font-family: 'roboto';
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
}

.footer-top ul li a:hover{
  color:#337ab7
}

.text-center{
  min-height: 50px;
  margin-bottom: 10px;
}

.footer-social{
  text-align: center;
}
.footer-social a{
  display: inline-block;
  height: 36px;
  width: 36px;
  border: 2px solid #ffffff;
  border-radius: 50%;
  text-align: center;
  margin: 0px 2px;
}
.footer-social a span{
  font-size: 20px;
  padding: 6px;
}
.footer-social a span svg{
  color: #ffffff;
}
.footer-social a:hover{
  filter: invert(51%) sepia(89%) saturate(5569%) hue-rotate(174deg) brightness(96%) contrast(101%);
}

.footer-newsletter label{
  font-size: 18px;
  font-weight: normal;
  margin-bottom: 5px;
}
.footer-newsletter .form-control{
  color: #ffffff;
  border-radius: 0px !important;
  border-color: #ffffff;
  background-color: #2ec3fd;
  height: 46px;
  font-style: italic;
  width: 60%;
  float:left;
}
.footer-newsletter .input-group-btn{
  width: 40%;
  float: right;
}
.footer-newsletter .btn{
  border-color: #ffffff;
  background-color: #ffffff;
  color: #008cc1;
  padding: 12px;
  text-transform: uppercase;
  border: 0px !important;
  border-radius: 0px !important;
  line-height: 1.4 !important;
}
.footer-newsletter .btn:hover{
  background-color: #008cc1;
  color:#FFF;
}

.textfb{
  line-height: 50px;
  color:#FFF;
  font-size: 14px;
  font-family: roboto3;
}

.displayContactos{
  text-align: right;
}

.telefonef{
  font-size: 22px;
  color:#FFF;
  font-weight: bold;
}

.emailf a{
  color:#FFF;
  font-size: 14px;
  font-family: roboto1;
}

.email span h4{
  font-size:1rem !important;
}


@media only screen and (max-width:1024px){
 
  .linksxs{
    display: none;
  }
  .temp{
    flex: 0 0 36.666667%;
    max-width: 36.666667%;
  }

  .textfb{
    font-size: 13px;
    line-height: 4;
  }
  .footer-top ul {
    float: none;
  }
  .footer-top ul li{
    width: 20%;
    float: right;
    margin-bottom: 0px;
  }

  .navlihidden{
    display: block !important;
  }
}

@media only screen and (max-width:770px){
 
  .newsletter{
    display: none;
  }
  .newsletterhidden{
    display: block !important;
    flex: 100%;
    max-width: none;
  }

  .navlihidden{
    display: none !important;
  }

  .rowvisiblefooter{
    display: none;
  }

  .rowvisiblehidden{
    display: block !important;
  }

  .footer-bottom{
    height: 110px;
  }
  .displayContactos{
    text-align: left;
  }
  .hiddentextfb{
    max-width: 100%;
  }

  .visibletemp{
    display:none !important;
  }

  .hiddentemp{
    display:block !important;
  }

  .hiddentempshow{
    flex: 50%;
    max-width: 50%;
  }

  .footer-social{
    margin-top: 12px;
  }

  .hiddentemp{
    flex:100%;
    max-width: 100%;
  }

  .contacts{
    flex: 100%;
    max-width: 100%;
  }


}



/* Servicos Nazaré */
.servicesN{
  background-color: #f7f7f7;
  text-align: center;
  padding: 2rem 0;
}

/* Servicos Alojamento */
.servicesA{
background-color: #f7f7f7;
text-align: center;
padding: 2rem 0;
}
/* Titulo Servicos */
.section-title-serv {
  text-align: left;
  margin-bottom: 2rem;
}

.section-title-serv-tit{
  margin-top:0;
}

.section-title-serv-tex{
  margin-top: 0;
}

.section-title-serv h4 {
  font-size: 40px;
  font-family: "roboto1";
  color: #504f46;
  padding-bottom: 10px;

}
.section-title-serv h6 {
  font-size: 22px;
  font-family: "roboto1";
  text-transform: capitalize;
  margin-bottom: 1rem;
  color: #2ec3fd;
  font-weight: lighter;
  padding-bottom: 10px;
}


.services-title {
  width: 95vw;
  max-width: 1170px;
  margin: 0 auto;
}

.leftS span img{
  width: 55px;
  height: 70px;
  padding-top: 0.5rem;
  
}

.rightSN{
  float: right;
  width: 70%;
  margin-top: 10px;
  text-align: left;
  color: #504f46;
}

.rightSN h6{
  font-family: 'roboto';
  font-weight: normal;
  font-size: 22px;
}


.contactopf{
  background-color: #f7f7f7;
  text-align: center;
  width: 100%;
}

.contactopfa{
  padding:25px;
  color:#504f46;
}


.contactopfa h2{
  color:#2dc3fc;
  font-size: 50px;
  font-family: 'roboto';
  font-weight: 500 !important;
}

.contactopfa h1{
  font-size: 30px !important;
  font-weight: 300 !important;
}
 
.contactopfa h3{
  font-size: 30px !important;
  font-weight: 300 !important;
}

@media only screen and (max-width:500px){
  
  .contactopfa h1{
    font-size: 30px;
  }
  .contactopfa h3{
    font-size: 25px;
  }
  
  .contactopfa h2{
    color:#2dc3fc;
    font-size: 35px;
    font-family: roboto3;
  }
}

@media only screen and (max-width:385px){
  
  .contactopfa h1{
    font-size: 25px;
  }
  .contactopfa h3{
    font-size: 20px;
  }
  
  .contactopfa h2{
    color:#2dc3fc;
    font-size: 20px;
    font-family: roboto3;
  }
}

/* APRESENTAÇÃO CONTACTOS*/



.apblockC{
  height: 435px;
  background-color:#fff;
}
.wrapper-apC{
  width: 1170px;
  margin: 0 auto;
}

.img-infoapC{
  width:50%;
  float:left;
  text-align:left;
  margin-top: 1rem;
}

.img-infoapC h3{
  padding: 20px 0px 0px;
  margin-bottom:5px;
  font-family: "roboto1";
  color: #504f46;
  line-height:44px;  
  font-size: 40px;
}

.img-infoapC h4{
  padding: 20px 0px 0px;
  margin-bottom:5px;
  color: #504f46;
  font-weight: normal;
  line-height:44px;  
  font-size: 25px;
}

.img-infoapC h5{
  padding: 0 0px 0;
  font-family: "roboto1";
  color: #2dc3fc;
  line-height:44px;
  font-size: 20px;
}

.img-infoapC p{
  padding: 0 0px 0px 0px;
  font-family: "roboto1";
  color:#353535;
  font-size: 15px;
  line-height:24px;
  margin-bottom: .5rem !important;
}


.img-infoapC p svg{
  color: #353535;
}
.img-infoapC{
  float:left;
  width: 50%;
}

.img-apC{
  padding-top:15px;
  padding-right:10px;
  width:50%;
  float:right

}

.img-infoapC span{
  margin-right: 10px;
}
.img-infoapC span a{
  font-size: 25px;
  color: #5f5f5f;
}

.img-infoapC span a:hover{
  color: #2dc3fc;
}



@media only screen and (max-width:1100px){

  .wrapper-apC{
    width: 100%;
    padding-left: 3rem;
  }

}

@media only screen and (max-width:830px){

  .wrapper-apC{
    width: 100%;
    padding-left: 3rem;
  }

  .img-infoapC h3{
    font-size: 30px;
  }
  
  .img-infoapC h5{

    font-size: 20px;
  }
  
  .img-infoapC p{
    font-size: 15px;
  }
  .img-infoapC span a{
    font-size: 20px;
  }

  .form-infoapC h2{
    font-size: 30px;
    padding-left: 0px;
  }

}

@media only screen and (max-width:620px){

  .wrapper-apC{
    width: 100%;
    padding-left: 2rem;
  }

  .img-infoapC h3{
    font-size: 25px;
  }
  
  .img-infoapC h5{

    font-size: 20px;
  }
  
  .img-infoapC p{
    font-size: 15px;
  }
  .img-infoapC span a{
    font-size: 20px;
  }


}

@media only screen and (max-width:500px){

  .wrapper-apC{
    width: 100%;
    padding-left: 1rem;
  }

  .img-infoapC h3{
    font-size: 20px;
  }
  
  .img-infoapC h5{

    font-size: 15px;
  }
  
  .img-infoapC p{
    font-size: 12px;
  }
  .img-infoapC span a{
    font-size: 20px;
  }

  .form-infoapC h2{
    font-size: 20px;
  }

}

@media only screen and (max-width:400px){

  .wrapper-apC{
    width: 100%;
    padding-left: 1rem;
  }

  .img-infoapC h3{
    font-size: 15px;
  }
  
  .img-infoapC h5{

    font-size: 13px;
  }
  
  
  .img-infoapC span a{
    font-size: 15px;
  }

  .form-infoapC h2{
    font-size: 15px;
  }

}

@media only screen and (max-width:360px){

  
  .img-infoapC p{
    font-size: 10px;
  }
 
}

/* END */

.leftSN{
  float: left;
  width: 10%;
  font-size: 33px;
  color:#2dc3fc;
}

.leftSN svg{
  padding: 2px;
}

.rightSNN{
  float: right;
  width: 85%;
  text-align: left;
  color: #504f46;
  padding-top: .5rem;
}

.rightSNN h6{
  font-family: 'roboto';
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 0px;
}

.rightSNN p{
  font-family: 'roboto';
  font-size: 16px;
}

/*  Slider */


/*  Formulario */

.row{
  margin-left: -10px;
  margin-right: -15px;
}
.booking-form{
  background: linear-gradient(to right, #2ec3fd 0%, #2ec3fd 50%, #f7f7f7 50%, #f7f7f7 100%);
  display: block;
  margin-bottom: 1rem;
}

.page-header{
  margin-top: 20px;
  border: 0px;
  margin-bottom: 20px;
}

.page-header h1{
  color: #504f46;
  font-weight: 600;
  font-family: "robotoHeader" !important;
  font-size: 40px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eeeeee;
}

.page-header p{
  font-weight: lighter;
  color: #2dc3fc;
  font-size: 22px;
  margin-bottom: 0px;
}


/* Formulario */

.container{
  width: 1170px !important;
}

.col-md-5{
  float: left;
}

.page-header{
  margin-top: 20px;
  border: 0px;
  padding-bottom: 9px;
  margin: 40px 0 20px;
}


.bPo{
  position: relative;
  width: 100%;
}
.col-xs-6{
  width: 50%;
  float: left;
  position: relative;
  min-height: 1px;
  padding-left: 10px;
  padding-right: 15px;
}

.col-md-6{
  margin-left: 8.33333333%;
  float:left;
  width: 50%;
}

.booking-form-personal{
  padding-left: 20px;
}

.booking-form, .form-horizontal, .control-label{
  text-align: left;
}

.form-horizontal, .control-label{
  margin-bottom: 0px;
  padding-top: 3px;
}
.col-xs-2{
  width: 16.66666667%;
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}

.label{
  max-width: 100%;
}

.col-xs-10{
  width: 83.33333333%;
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}

@media only screen and (max-width:764px){
  .row1{
    flex-direction: column;
  }
  .booking-form{
    background: linear-gradient( #2ec3fd 0%, #2ec3fd 50%, #f7f7f7 50%, #f7f7f7 100%);
  }

  .col-md-6{
    margin-left:0% !important;
    margin-top: 1rem;
    padding:0 !important;
  }
  .col-md-5{
    padding:0 !important;
    padding-left: 1.2rem !important;
    padding-right: 1rem !important;
  }
  .container{
    width: 100% !important;
  }
}

@media only screen and (max-width:420px){
.page-header h1{
    font-size: 30px;
}
.col-xs-10{
  padding-left: 0 !important;
}
}

.centerblockrsv{
  display: flex;
  width: 800px;
  margin:0 auto;
  height: 110px !important;
}

.rsvleft{
  float:left;
  width: 55%;
  text-align: center;
}

.rsvleft h2{
  padding-top: 1.9rem;
  color:#FFF;
  font-size: 36px;
  font-weight: lighter;
  
}

.rsvright{
  float:right;
  width: 45%;
}

.rsvq{
  padding: 24px 28px;
  margin-top: 1rem;
  text-transform: uppercase;
  background-color: #f9c500;
  outline: none;
  border: none;
  color:white;
  font-family: roboto3;
}

.rsvq:hover{
  background-color: #337ab7;
}

.rsvcontainer{
  background:#2ec3fd;
  align-items: center;
  width: 100%; 
}

@media only screen and (max-width:900px){
  .centerblockrsv{
    width: 100%;
    flex-direction: column;
  }
  .rsvright{
    float:none;
    width: 100%;
    text-align: center;
  }
  .rsvleft{
    float:none;
    width: 100%;
    text-align: center;
  }
  .rsvleft h2{
    font-size: 20px;
  }
  .rsvcontainer{
    height: 170px;
  }
}




.roomS {

  cursor:pointer
}
.roomS:hover {
  border: 1px solid #2dc3fc;
}

/* Banner RSV */
.containerRSV{
  height: 110px;
  display: flex;
}
.containerRSVLEFT{
  flex-grow: 2;
  background-color: #f8c500;
  text-align: right;
}
.containerRSVCENTER{
  flex-grow: 2;
  display: flex;
}

.containerRSVRIGHT{
  flex-grow: 2;
  display: flex;
  background-color: #2ec3fd;
  align-items: center;
  justify-content: center;
}

.DayPickerInput input{
  height: 100%;
  width: 80%;
}

.inputRSV{
  display: flex;
  flex-direction: column;
  border-right: 1px solid #f5f5f5;
}

.inputRSV h4{
  flex-grow: 2;
  margin: 20px 20px 0px 20px;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
}

.inputRSV .DayPickerInput{
  margin: 0px 10px 20px 0px;
  flex-grow: 2;
}

.inputRSV .DayPickerInput .input:focus{
  outline:none;
}

.inputRSV input{
  margin: 0px 0px 0px 20px;
  flex-grow: 2;
  border:none;
  font-size: 25px;
  
}

.inputRSV .DayPickerInput input{
  color:#f8c500;
}

.containerRSVLEFT h4{
  color: #fff;
  font-size: 22px;
  font-weight: bold;
  margin-top: 20px;
  padding-right: 1rem;
}

.containerRSVLEFT p{
  color: #000000;
  font-weight: bold;
  font-size: 16px;
  padding-right: 1rem;
}

.containerRSVRIGHT button{
  border: none;
  color:#FFF;
  background-color: #2ec3fd;
  font-size: 18px;
}

.containerRSVRIGHT button:hover{

  color:#f8c500;
}

.inputRSVtext{
  color:#f8c500;
  margin:0px 0px 15px 0px;
  font-size: 25px;
}

.form-infoapC{
  float:right;
  width: 50%;
}

.form-infoapC h2{
  padding-top: 1.5rem;
  padding-left: 3.5rem;
  font-size: 30px;
  margin-bottom: 20px;
  color: #353535;
  font-weight: normal;
}

.btn-primary1 {
  background: #f9c500 !important;
  text-transform: uppercase;
  cursor: pointer;
  font-family: "roboto3";
  color:#fff !important;
}
.btn-primary1:hover {
  background: #2ec3fd !important;
  text-decoration: none;
}


.section-title-rsv {
  text-align: left;
  margin-bottom: 1rem;
}

.section-title-rsv-tit{
  margin-top:0;
}

.section-title-rsv-tex{
  margin-top: 0;
}

.section-title-rsv h4 {
  font-size: 40px;
  font-family: "roboto1";
  text-transform: capitalize;
  color: #504f46;
  font-weight: lighter;
  padding-bottom: 5px;
  color: #504f46;
}
.section-title-rsv h6 {
  font-size: 1.5rem;
  font-family: "roboto1";
  text-transform: capitalize;
  margin-bottom: 1rem;
  color: #2ec3fd;
  font-weight: lighter;
  padding-bottom: 10px;
}

.apblockR{
  height: 200px;
}


@media only screen and (max-width:1024px){
  .apblockR{
    height: 220px;
  }
  
}

@media only screen and (max-width:700px){
  .apblockR{
    height: 265px;
    padding-left: 1rem;
  }
}

.leftSH{
  float: left;
  width: 25%;
}

.leftSH span img{
  width: 50px;
  height: 50px;
  margin-top: 0.6rem;
}

.leftSH span{
  filter: invert(53%) sepia(92%) saturate(650%) hue-rotate(168deg) brightness(105%) contrast(98%);
}

.nazarepf{
  background-color: #2dc3fc;
  text-align: center;
  width: 100%;
  height: 100px;
}

.nazarepfa{
  padding: 15px;
  color:#fff;
}

.nazarepfa a{
  outline:none;
  border: none;
  background-color:  #f9c500;
  color:#FFF;
  padding: 24px 35px;
  text-decoration: none;
}

.nazarepfa a:hover{
  background-color: #337ab7;
  text-decoration: none;
  color:#FFF;
}

.nazarepfleft{
  float: left;
  width: 50%;
  text-align: right;
}

.nazarepfleft h2{
  padding-top: .7rem;
  font-family: 'roboto';
  font-size: 36px;
  font-weight: lighter;
}

.nazarepfright{
  float: right;
  width: 50%;
  padding-top: 1.5rem;
  text-align: left;
  padding-left:3rem;
}

@media only screen and (max-width:800px){
  .nazarepfleft h2{
    font-size:30px;
    padding-top: 1.2rem;
  }
}
@media only screen and (max-width:700px){
  .nazarepfleft h2{
    font-size:  25px;
    padding-top: 1.3rem;
  }
}

@media only screen and (max-width:550px){
  .nazarepfleft h2{
    font-size:  20px;
    padding-top: 1.5rem;
  }
  .nazarepfright span a{
    padding: 15px 15px;
  }
}

@media only screen and (max-width:450px){
  .nazarepfleft h2{
    font-size:  19px;
    padding-top: 1.5rem;
  }
  .nazarepfright span a{
    padding: 11px 10px;
  }
}


@media only screen and (max-width:430px){
  .nazarepfleft h2{
    font-size:  17px;
    padding-top: 1.7rem;
  }
  .nazarepfright span a{
    padding: 10px 10px;
  }
}

@media only screen and (max-width:390px){
  .nazarepfleft h2{
    font-size:  15px;
    padding-top: 1.9rem;
  }
}

@media only screen and (max-width:340px){
  .nazarepfleft h2{
    font-size:  13px;
    padding-top: 1.9rem;
  }
}


.hero {
  position: relative;
}

.hero-overlay {
 text-align: center;
 position: absolute;
 z-index: 0;
 max-width: 30%;
  right: 10%;
  top: 30%;
  text-align: left;
}

.hero-overlay h1 {
  font-size: 96px;
  font-family: 'roboto';
  font-weight: bold;
  color: #2dc3fc;
  text-transform: uppercase;
}

.hero-image {
 display: block;
  overflow: visible;
 z-index:-1;
 margin-left: auto;
 margin-right: auto;
 height: 100%;
 width: 100%;
}

.hero-overlay p {
  background-color: #ffffff;
  padding: 15px;
  font-family: roboto1;
  font-size: 14px;
 }

 @media (max-width:1100px){
  .hero-overlay{
    display: none;
  }
 }


 @media only screen and (max-width:800px){
   .section-title h4{
     font-size: 35px;

   }
   .section-title-quartos-tit h4{
    font-size: 35px;

  }
  .section-title-quartos-tex h6{
    font-size: 20px;

  }
  .section-title-quartos-tex p{
    font-size: 14px;

  }

 }

 @media only screen and (max-width:550px){
  .section-title h4{
    font-size: 30px;
  }
  .section-title-quartos-tit h4{
    font-size: 30px;

  }
  .section-title-quartos-tex h6{
    font-size: 20px;

  }
  .section-title-quartos-tex p{
    font-size: 13px;

  }

}

@media only screen and (max-width:450px){
  .section-title h4{
    font-size: 25px;
  }
  .section-title-quartos-tit h4{
    font-size: 25px;

  }
  .section-title-quartos-tex h6{
    font-size: 15px;

  }
  .section-title-quartos-tex p{
    font-size: 12px;
  }
  .section-title-serv-tit h4{
    font-size: 25px;
  }
  .section-title-serv-tex h6{
    font-size: 20px;
  }

}

.oferta-info {
  display: table;
  position: relative;
  border: 1px solid #f2f2f2;
  border-top: 0px;
  width: 100%;
}

.img-container h3{
  color: #ffffff;
    font-size: 16px;
    font-weight: bold;
    left: 20px;
    bottom: 30px;
    position: absolute;
}

.container-oferta h2{
  color:#2ec3fd;
  font-family: roboto3;
  font-size: 25px;
}

.container-oferta p{
  color: #353535;
}

.img-container-oferta {
  position: relative;
}
.img-container-oferta img {
  width: 100%;
  display: block;
}

.containerof {
  position: relative;
}

.image {
  display: block;
  width: 100%;
  height: auto;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: #FFF;
  border: 1px solid #2dc3fc;
}

.containerof:hover .overlay {
  opacity: 1;
}

.part1oferta, .part2oferta, .part3oferta{
  padding-left: 1rem;
  padding-top: 1rem;
}

.part1oferta{
  padding-bottom: 0.8rem;
}


.containeroverlay h2{
  color:#2dc3fc;
  font-size: 30px;

}

.containeroverlay h5{
  color:#504f46;
  font-size: 16px;
}


.rsvbutton{
  width: 100%;
  background-color: #f8c500;
  color:#FFF;
  font-size: 25px;
  padding:10px;
  outline: none;
  border: none;
  position: absolute;
  bottom: 0;
  text-align: center;
}

.rsvbutton:hover{
  background-color: #2dc3fc;
  text-decoration: none;
  color:#fff;
}

.oferta-info {
  padding: 20px;
  background-color: #ffffff;
}
.oferta-left{
  display: table-cell;
  padding-left: 15px;
  padding-right: 15px;
  height: 100%;
  vertical-align: middle;
  width: 99%;
  text-align: center;
}

.oferta-left h3{
  font-size: 20px;
  font-family: "roboto3";
  color:#2dc3fc;
  padding-top: 0.3rem;
}
.hiddenoferta{
  position: absolute;
    top: 0px;
    z-index: 999;
    background-color: #ffffff;
    height: 100%;
    width: 100%;
    visibility: hidden;
}
.opcoesoferta{
  padding: 20px;
}
.opcoesoferta h2{
  color:#2dc3fc;
  font-size: 22px;
  font-weight: bold;
}
.opcaooferta h5{
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 5px;
  color:#353535;
}

.opcaooferta p{
  font-size: 14px;
  margin-bottom: 5px;
  color:#353535;
}

.precoofertahidden{
  font-weight: bold;
  font-size: 28px;
  color: #2ec3fd;
  margin-bottom: .5rem;
}

.ofertaadicional{
  font-size: 14px;
  margin-bottom: 5px;
  color:#353535;
}

.ofertabotoes{
  position: absolute;
  bottom: 0px;
  width: 100%;
}

.btn-book{
  padding: 10px 16px !important;
  font-size: 27px !important;
  font-weight: bold !important;
  background-color: #2ec3fd !important;
  border-color: #2ec3fd !important; 
  color:#FFF !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.btn-close{
  border-color: #f9c500 !important;
  background-color: #f9c500 !important;
  color:#FFF !important;
  padding: 10px !important;
}

.btn-close:hover{
  border-color: #337ab7 !important;
  background-color: #337ab7 !important;
}
.container-oferta button{
  color: #504f46 !important;
  font-size: 13px !important;
  font-weight: bold;
  text-decoration: none !important;
}

.container-oferta button:hover{
  color:#2dc3fc !important;
}

.col-xs-12{
  width: 100%;
}

.rooms-list-text p{
  font-size: 18px;
    font-weight: lighter;
}

.offer-ribbon{
  border-style: solid;
  border-width: 0 0 110px 110px;
  border-color: transparent transparent #2ec3fd transparent;
  position: absolute;
  bottom: 0;
  right: 0;
}

.ribbon-text{
  position: absolute;
  bottom: 0;
  right: 0;
  text-align: right;
  margin-right: 6px;
  color:#FFF;
}

.ribbon-text span{
  display: block;
  font-style: italic;
  font-size: 12px;
  margin-bottom: -4px;
}

.ribbon-text h3{
  font-size: 28px;
  font-weight: bold;
}
@media only screen and (max-width: 470px){
  .ofertav2{
    padding:0
  }
  
}
@media only screen and (max-width:375px){
  .room{
    padding-right: 3rem;
    padding-left: 1rem;
  }
}
@media only screen and (max-width:325px){
  .room{
  padding-right: 7rem;
  padding-left: 1rem;
}
  .oferta{
    padding:0px;
    margin-left:2rem;
  }
  .part1oferta h2{
    font-size:10px;
  }
  .part1oferta h5{
    font-size: 10px;
  }
  .part1oferta p{
    font-size:11px;
  }
  .part2oferta h5{
    font-size: 10px;
  }
  .part2oferta p{
    font-size:11px;
  }
  .part3oferta p{
    font-size: 9px;
  }

}

@media only screen and (max-width:400px){

  .container-oferta h2{
    font-size: 20px;
  }
  .container-oferta p{
    font-size: 15px;
  }
  .containeroverlay h2{
    font-size: 20px;
  
  }
  
  .containeroverlay h5{
    font-size: 12px;
  }

  .part2oferta, .part3oferta{
    padding-top: 0.3rem
  }
  
}

.featured-quotes {
  width: 1170px;
  margin: auto;
  height: 485px;
}

@media (max-width:1170px){
  .featured-quotes {
  width: 100%;
  height: auto;
  margin: 0 auto;
  }
}

.divisor h4{
  font-weight: lighter;
  font-size: 1.3rem;
}

@media (min-width: 1200px){
  .container{
    max-width: 1170px !important;
  }
}

.col-md-4{
  padding-left: 0px !important;
}

.btnenviarv2{
  text-align: right;
}

.btnenviarv2 button{
  text-transform: none;
  display: inline-block;
  font-weight: normal;
  text-align: center;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
  padding: 10px 25px;
  font-size: 16px;
  line-height: 1.42857143;
  border-color: #2dc3fc;
  background-color: #2dc3fc;
  color: #fff;
}

.btnenviarv2 button:hover{
  background-color: #337ab7;
  border-color: #337ab7;
}


.leftSNA{
  float: left;
  width: 30%;
  text-align: left;
}
.rightSNA{
  float: right;
  width: 70%;
  text-align: left;
  padding-left: 1rem;
  color: #504f46;
}

.rightSNA h5{
  font-size: 16px;
}
.rightSNA p{
  margin-bottom: 0px;
  font-size: 16px;
}


.rightSS{
  float: right;
  width: 70%;
  text-align: left;
  color: #504f46;
}

.rightSS h6{
  font-size: 22px !important;
  margin-bottom: 5px !important;
}
.rightSS p{
  margin-bottom: 0px;
  font-size: 14px;
}

.leftSS{
  float: left;
  width: 25%;
}

.leftSS span img{
  width: 50px;
  height: 50px;
  margin-top: 0.7rem !important;
}

.leftSS span{
  filter: invert(59%) sepia(0%) saturate(0%) hue-rotate(151deg) brightness(96%) contrast(95%);
}

.ril__toolbar{
  background-color: transparent !important;
}

.ril__zoomInButton{
  display: none;
}
.ril__zoomOutButton{
  display: none;
}


.PageS p{
 font-style: it;
}

.form-control{
  border-radius: 0 !important;
}

.slick-dots{
  bottom: -40px !important;
}
.slick-dots li{
  width: 12px !important;
  height: 12px !important;
  margin: 0 5px;
  border: 2px solid #2ec3fd;
  border-radius: 10px;
}

.slick-dots li.slick-active button:before, .slick-dots li button:before{
  color:transparent !important;
}

.slick-dots .slick-active {
  background-color: #2ec3fd;
}

.map{
  height: auto;
}

.roomS{
  border:1px outset transparent;
}

/* Share Box */

#exampleFormControlSelect1 option{
  background: #FFF;
  color: #504f46;
}
#apartamento option{
  background: #FFF;
  color: #504f46;
}

#adulto option{
  background: #FFF;
  color: #504f46;
  font-size: 14px;
}

#crianca option{
  background: #FFF;
  color: #504f46;
  font-size: 14px;
}

#oferta option{
  background: #FFF;
  color: #504f46;
  font-size: 14px;
}

#oferta2 option{
  background: #FFF;
  color: #504f46;
  font-size: 14px;
}


select {
	background: #F0F3FB;
	border: 1px solid #F0F3FB;
	border-radius: 4px;
	width: 100%;
	padding: 20px;
	font-size: 16px;
	color: #3F3F3F;
  
	/* Here's the code we need */
	-webkit-appearance: none;
	-moz-appearance: none;
	-ms-appearance: none;
	 -o-appearance: none;
    appearance: none;
    
  
}



.temp{
  display: inline-block;
  vertical-align: middle;
}

.temp p{
  font-size: 30px;
  color:#FFF;
  margin-top:10px;
}

.input-group-text{
  color:#FFF;
  background-color: transparent;
  border-radius: 0;
  font-size: 14px;
}

.dropdown-menu{
  background-color:#2dc3fc !important;

}

.dropdown-item{
  background-color:#2dc3fc !important;
}

.form-control:focus{
  box-shadow: 0 0 0 0 rgba(0,123,255,.25);
}


.input-group .DayPickerInput input{
  width: 100%;
  background-color: #2ec3fd;
  border:none;
  padding-left: 5px;
  color:#FFF;
}

#oferta2{
  visibility: hidden
}


/* Comodidades Apartamento */

.leftSApart{
  float: left;
  width: 10%;
  text-align: left;
}
.leftSApart i img{
  width: 20px;
  height: 21px;
  filter: invert(35%) sepia(0%) saturate(2726%) hue-rotate(133deg) brightness(97%) contrast(82%);
}
.rightSApart{
  float: right;
  width: 90%;
  text-align: left;
  padding-left: 1rem;
  color: #504f46;
}

.rightSApart h5{
  font-size: 16px;
}
.rightSApart p{
  margin-bottom: 0px;
  font-size: 16px;
}


/* PAGINA HOME RESPONSIVE */
.alojamentopfh{
  background-color: #f8c500;
  text-align: center;
  width: 100%;
  display: none;
}
@media (max-width:1220px){
  .containerRSVLEFT{
    display: none
    
  }
  
  .containerRSVRIGHT button{
    font-size: 15px;
  }
}


@media (max-width:900px){
  .alojamentopfh{
    display: block;
  }

  .containerRSV{
    display: none;
  }
}

@media (max-width:777px){
  .carousel-caption{
    display:none;
  }
}
/* FIM */

.nav-righBTN{
  float: right;
  margin-top: 0.6rem;
  display:none
}
.navbarcollapse{
  display: none;
}

.navbarcollapse li{
  font-size: 14px;
  height: 60px;
}

.navbarcollapse li a{
  text-decoration: none;
  font-weight: bold;
  text-transform: uppercase;
  color:#353535;
  display: block;
  padding-top: 1.25rem;
  padding-left: .8rem;
  
}

.navbarcollapse li a:hover{
  color:#FFF;
}

.navbarcollapse li:hover{
  background-color: #2dc3fc;
  color: #FFF;
}

@media (max-width:767px){
  .nav-righBTN{
    display: block;

  }
  .navbarcollapse{
    display:block
  }
}

.header-top-rightLANG{
  display: none;
  float: right;
}

.dropdown-toggle {
 color:#FFF
}
@media (max-width:767px){
  .header-top-rightLANG{
    display: flex;
    flex-direction: row;
    padding-top:.3rem;
  }

  .nav-left{
    margin-left: 0.6rem;
  }
}

.splitRoom{
  display: none;
}

@media (max-width:767px){
  .header-top-left{
    display: none;
  }

  .header-top-left2{
    display: block;
    margin-left: 1rem;
  }
  
}


@media (max-width: 990px){
  .leftd{
    display: none;
  }
  .rightd{
    display: none;
  }
  .centerd h2{
    font-size: 24px !important;
  }
  .centerd{
    width: 100%;
  }
  .splitRoom{
    display: flex;
  }
}

@media (max-width: 1200px){
  .mainSpanContact{
    display: flex;
  }

  .apblockC{
    height: 720px;
  }

  .img-infoapC{
    width: 100%;
  }

  .form-infoapC{
    width: 80%;
    float: none;
  }
  .form-infoapC h2{
    padding-left: 0px;
  }
  .col-sm-6{
    margin-left: 0px;
  }
  .mediumSpanContact{
    width: 55%;
  }
  .mediumSpanContact2{
    width: 45%;
    margin-top: 10rem;
    margin-bottom: 1rem;
  }
}

@media(max-width: 800px){
  .mainSpanContact{
    flex-direction: column;
  }
  .mediumSpanContact2{
    margin-top: 0px
  }
  .apblockC{
    height: 830px;
  }
}
@media(max-width: 600px){
  .nazarepfright a {
    font-size: 11px;
  }
}
@media(max-width: 1000px){
  .control-label{
    padding-left: 0;
  }
}
@media(max-width: 450px){
  .mediumSpanContact2 h4{
    font-size: 17px;
  }
  .col-xs-2 {
    padding-left: 0;
  }
  .labelPeq{
    font-size: 11px !important;
  }
  .nazarepfright a {
    font-size: 10px;
  }
}

.placeholderNewsletter::placeholder{
  color:#FFF !important;
}


.home-slider .background-fill{
  position: absolute;
  top: 0px;
}

.background-fill{
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
}

